import React from 'react';
import UpdateFactor from './UpdateFactor';
import UpdateIssue from './UpdateIssue';
import { useSelector, useDispatch } from 'react-redux';
import {removeFactorFromDay, removeIssueFromDay, deleteActiveDay} from '../calendar/CalendarSlice';
import {selectFactors} from '../factor/factorSlice';
import {selectIssues} from '../issue/IssueSlice';
import nameOfDays from '../../helpers/nameOfDays';
import {useHistory} from 'react-router-dom';
import {setAnalysisFlag} from '../analysis/AnalysisSlice';


function UpdateDayContainer(props) {
    const myFactors = props.aDay.myFactors;
    const myIssues = props.aDay.myIssues;
    const factors = useSelector(selectFactors);
    const issues = useSelector(selectIssues);
    const history = useHistory();

    const dispatch = useDispatch();

    function removeDay(day) {
        let myFactors=day.myFactors;
        myFactors.map((key) => {
            dispatch(removeFactorFromDay({day, factor:factors[key]}));
        });

        let myIssues=day.myIssues;
        myIssues.map((key) => {
            dispatch(removeIssueFromDay({day, issue:issues[key]}));
        });


        // remove factors ->and the 
        // remove issues
        // remove activeDay
        // history ->back
        dispatch(deleteActiveDay(day));
        props.setDeletedDay(true);
//        history.goBack();

    }

    function removeFactor(day) {
        return function(factor) {
            dispatch(removeFactorFromDay({day, factor}));
            dispatch(setAnalysisFlag(true));
        }
    }
    const fRemoveFactor= removeFactor(props.aDay);
    
    const listFactors = myFactors.map((key) => {
        return (<UpdateFactor
            key = {key} 
            factor = {factors[key]}
            removeItem = {fRemoveFactor}
        />)
    });

    function removeIssue(day) {
        return function(issue) {
            dispatch(removeIssueFromDay({day, issue}));
            dispatch(setAnalysisFlag(true));
        }
    }
    const fRemoveIssue= removeIssue(props.aDay);

    const listIssues = myIssues.map((key) => {

        return (
            <UpdateIssue
            key = {key} 
            issue = {issues[key]}
            removeItem = {fRemoveIssue}
        />)
    });
    return (
        <div className="update-day">
        <ul className="listissues">{listIssues}</ul>        
        <div className="date-format"> {nameOfDays(props.aDay.title)} <br />{props.niceDate}<br />Remove Day <span onClick={()=> removeDay(props.aDay)}>&#10007;</span></div> 


        <ul className="listfactors">{listFactors}</ul>
        </div> 
    )
}

export default UpdateDayContainer;