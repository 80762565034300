import React from 'react'
import {
  Link

} from "react-router-dom";
export function LandingPage() { 
  return (
    <section id="settings">
        <h2>Coincidence Calendar</h2>
        <p><span role="img">&#127974;</span> Why do things happen? When do they happen? Are there reasons or just events?</p>
        
        <p><span role="img">&#128203;</span> Are my headaches caused by cheese? Or is it the red wine? Am I lucky when I've got a four-leaf clover in my wallet? 
          Manage your <Link to="manage">factors and issues</Link>. You can share and receive these too.</p>
        <p><span role="img">&#128197;</span> Record events in a calendar. Two types of calendar: <Link to="/calendar">monthly</Link> and <Link to="/scrollcalendar">12 month</Link> scrolling. 
          Mobile and desktop (classic) friendly layout.</p> 
        <p><span role="img">&#128202;</span> Look for relationships and connections by <Link to="/analysis">analysing your calendar</Link>. Don't forget. The more days and data the more reliable the results.</p>
        <p><span role="img">&#128736;</span> Make changes to how it all works and save and load your data in the <Link to="/settings">settings</Link>. Download an Excel friendly version too. Send it to your doctor.</p>
        <p>Any questions? info@CatDogCar.com</p>
        <div>
          <hr />
        <h3>Please Watch the Introduction Video</h3>  
        <video className="video" controls>
          <source src="overview.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>
        <hr /> 
        <h3>Desktop and Excel Export</h3>
        <video className="video-horiz" controls>
          <source src="overviewHoriz.webm" type="video/webm" />
          Your browser does not support the video tag.
        </video>         
        </div>
    </section>
    )
}
export default LandingPage;