import React, {useEffect, useState}  from 'react'
import {selectFactors} from './factor/factorSlice';
import { useSelector, useDispatch } from 'react-redux';
import {selectIssues} from './issue/IssueSlice'
import ManageFactor from './manage/ManageFactor';
import ManageIssue from './manage/ManageIssue';
import AddValues from './manage/AddValues';
import {selectCompleteState} from './calendar/CalendarSlice';
import ImportJSON from './settings/ImportJSON';
import ExportJSON from './settings/ExportJSON';
import {setAnalysisFlag} from './analysis/AnalysisSlice';
import {saveStateToLocal} from '../helpers/saveStateToLocal';

export function ManageFandI() { 
  const factors = useSelector(selectFactors);
  const issues = useSelector(selectIssues);
  const state = useSelector(selectCompleteState);
  const dispatch = useDispatch();
  const [submitText, setText] = useState('Submit');
  const [values, setValues] = useState({id:null,title:'', desc:''});
  let [isFactorsShowing, setIsFactorsShowing] = useState(false);

  useEffect(() => {
      return () => {
              try {
                dispatch(setAnalysisFlag(true));
                saveStateToLocal(state);
              } catch (e) {
                console.warn(e);
              }
          }
    }, [state, dispatch]);

    const listIssues =  Object.keys(issues).map((key) => {
      return (
          <ManageIssue
          setText = {setText}
          setValues = {setValues}
          key = {key} 
          issue = {issues[key]}
      />)
  });

  const listFactors = Object.keys(factors).map((key) => {
      return (<ManageFactor
        setText = {setText}
        setValues = {setValues}
        key = {key} 
        factor = {factors[key]}
      />)
  });


  return (
    <section id="update-day"> 


 {!isFactorsShowing ? (
  <div className="grid-container">

    <div className="grid-item">
            <h3>Issues <span className="unselected"  onClick={()=> { setIsFactorsShowing(!isFactorsShowing); setValues({id:null, title: '', desc: ''}); }
 }>Factors</span></h3>      
            <ul className="listissues">{listIssues}</ul>       
            </div> 
    <div className="grid-item">

        <AddValues
          submitText = {submitText}
          setValues = {setValues}
          values = {values}
          addType = "issue" 
        />
Add a new Issue, <br />edit &#9998; an existing one, or, <br />delete &#10007; .
      </div>


    </div>

) : (
  <div className="grid-container">
    <div className="grid-item">
            <h3><span className="unselected" onClick={()=>{setIsFactorsShowing(!isFactorsShowing); setValues({id:null, title: '', desc: ''}); } }>Issues</span> Factors</h3>
      <ul className="listfactors">{listFactors}</ul>
      </div>
      <div className="grid-item">

      <AddValues
        submitText = {submitText}
        setText = {setText}
        setValues = {setValues}
        values = {values}
        addType = "factor" 
      />
Add a new Factor, <br />edit &#9998; an existing one, or, <br />delete &#10007; .

  </div>
      </div>
      )}
      <hr />
        <h3>Share and Receive Issues and Factors</h3>    
 
        Share with a friend: 
        <ExportJSON />   <br />   
 
        Receive from a friend: <br />
        <ImportJSON />
        
  </section>
  )
}

export default ManageFandI;