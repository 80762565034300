import React from 'react';
import FormatDate from '../helpers/FormatDate';
import { useSelector, useDispatch } from 'react-redux';
import Day from './calendar/Day';
import MonthSelector from './calendar/MonthSelector';
import {useHistory} from 'react-router-dom';
import {selectAnalysisTable, selectSelectedIssue, setComleteAnalysisTable, selectAnalysisFlag, setAnalysisFlag } from './analysis/AnalysisSlice';
import {addNewActiveDay, selectActiveDays, selectStartDate} from './calendar/CalendarSlice';
import {selectToday, setSelectedDay, selectCurrentView, setCurrentView} from './calendar/TemporalSlice';

import {selectFactors} from './factor/factorSlice';
import {selectIssues} from './issue/IssueSlice';

import analysisEngine from './analysis/AnalysisEngine';
import years from '../helpers/years';

function Calendar (props) {

    let currentView = useSelector(selectCurrentView)
    const startDate = useSelector(selectStartDate);
    const dispatch = useDispatch();

    function changeMonth(currentView, startDate, today) {
        return  (direction) => {
            let newView = {year: currentView.year, month: currentView.month};
            dispatch(setSelectedDay({today:null}));
            if (direction==='up' && !(today.month===currentView.month && today.year===currentView.year)) {
                if (currentView.month===12) {
                    newView.month=1;
                    newView.year = currentView.year+1;
                } else {
                    newView.month=currentView.month+1;
                }
            }
            if (direction==='down' && !(currentView.month===startDate.month && currentView.year===startDate.year))  {
                if (currentView.month===1) {
                    newView.month=12;
                    newView.year = currentView.year-1;
                } else {
                    newView.month=currentView.month-1;
                }
            }
            if (currentView.year!==undefined) {
               dispatch(setCurrentView({newView}));
            }
        }
    }

    const history = useHistory();
    const today = useSelector(selectToday);
    const factors = useSelector(selectFactors);
    const issues = useSelector(selectIssues);
    const activeDays = useSelector(selectActiveDays);
    const initialise = useSelector(selectAnalysisFlag);

    if (initialise) {
        dispatch(setAnalysisFlag(false));
        const result = analysisEngine.generateCompleteAnalyisTable(issues, factors, activeDays);
        dispatch(setComleteAnalysisTable({result}));
      }
    
    const listDays = [];
    const formatDate = FormatDate(currentView);
    const selectedIssue = useSelector(selectSelectedIssue);
    const analysisTable = useSelector(selectAnalysisTable);
//    let padding = null;

    const currentAnalysis = (selectedIssue===null || analysisTable[selectedIssue.key]===undefined)? {}:analysisTable[selectedIssue.key];
    let days = (today.month===currentView.month && today.year===currentView.year)? today.day:years[currentView.year][currentView.month-1].days; 
    let titleNo = new Date(formatDate(1)[0]).getDay(); // Note: Sunday is 0, Monday is 1, and so on.
    const padding = (titleNo===0)? 6:titleNo-1;

    for (let i=1; i<=days;i++) {
        let [dayValue, niceDate] = formatDate(i);                    
        let dayObj =  (activeDays[dayValue]!== undefined)? activeDays[dayValue]: {id: dayValue, title: titleNo, myIssues : [],myFactors: []}; 

        const calendarClick = () => {
            dispatch(addNewActiveDay({today: dayObj})); // conditional in slice
            dispatch(setSelectedDay({today: dayValue}));
            let updatedPath = history.location.pathname+'#a'+dayValue;
            history.replace(updatedPath);
            history.push('/update_day');
        };

            listDays.push (
               (
               <Day
               key = {dayValue} 
               calendarClick= {onClick => calendarClick()}
               niceDate = {niceDate}

               date = {dayValue}
               aDay = {dayObj} 
               currentAnalysis = {currentAnalysis}
               selectedIssue = {selectedIssue}

               /> )
            );
            titleNo = (titleNo===6)? 0:titleNo+1;

    }
//    padding = 0; // turn off padding for now
    let padDivs =[];
    for (let i=0; i<padding;i++) {
        padDivs.push((<div key={i} className="blank_day day"></div>));
    }
    
    return (<div>
            <section id="cal-control">
        <MonthSelector
            currentView = {currentView }
            startDate = {startDate}
            today = {today}
            clickMonthUp= { changeMonth(currentView, startDate, today)}
            clickMonthDown= { changeMonth(currentView, startDate, today)}
        />
    </section>
       <section id="weekview"> {padDivs} {listDays }</section>
       </div>
   )
}

export default Calendar;