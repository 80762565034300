import React from 'react';
import ReactDOM from 'react-dom';
import './normalize.css';
import './index.scss';
import store from './app/store';
import { get } from 'idb-keyval';
import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import App from './App';

  
  async function asyncCall() {
    const indexedDBValues = await get('state');
    ReactDOM.render(  
        <Provider store={store(indexedDBValues)}>
            <App />
        </Provider>, 
        document.getElementById('root'));
  }
  
  asyncCall();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
