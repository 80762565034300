import { createSlice } from '@reduxjs/toolkit';
import today from '../../helpers/today';

export const temporalSlice = createSlice({
  name: 'temporal',
  initialState:  {
    selectedDay: today().full, // this should be an string which in the selector will return the calendar map object
    today: today(), 
    currentView: {year: today().year, month: today().month},
  },
  reducers: {
    setCurrentView: (state, action) => {
      state.currentView=action.payload.newView;
    },

    setSelectedDay:(state, action) => {
      state.selectedDay= action.payload.today;
  },
  }
});

export const { setCurrentView, setSelectedDay} = temporalSlice.actions;

export const selectToday = state => state.temporal.today;
export const selectSelectedDay = state=> state.temporal.selectedDay;
export const selectCurrentView =  state => state.temporal.currentView;

export default temporalSlice.reducer;