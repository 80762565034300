import React from 'react';
import { useDispatch } from 'react-redux';
import { setFactor } from './AnalysisSlice';
import codeCorr from '../../helpers/codeCorr';

function DisplayFactorWithHeat(props) {
    const dispatch = useDispatch();
    const factor = props.factor;
    let corr = (props.corr===undefined)? NaN: props.corr.corr;
    let selFlag =(props.selFlag)? "sel-analysis":"";
    let classes =codeCorr(corr)+" "+selFlag;
    return (
       <li key={factor.key} className={classes} onClick={() => {
        dispatch(setFactor({factor}));
 //       props.setFactorState(factor); 
    }
    }>
                <span>
       {props.factor.title} </span> 
       </li>
    );
// {payload: {day: theDay, factor: factor}}
}
export default DisplayFactorWithHeat;