let months = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September',
    'October', 'November', 'December'
    ];

function newFormtDate(currentDate) { // js Date

        let month = months[currentDate.getMonth()];
        let mm = String(currentDate.getMonth()+1).padStart(2, '0'); //January is 0!
        let dd = String(currentDate.getDate()).padStart(2, '0'); 
        let dayValue =currentDate.getFullYear()+'-'+ mm +'-'+dd;
        let niceDate = dd+" "+month+" "+currentDate.getFullYear();
        return [dayValue, niceDate];
}

export default newFormtDate;
