import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {selectApp} from '../../app/AppSlice';
import {restoreFactorState} from '../factor/factorSlice';
import {restoreIssueState} from '../issue/IssueSlice';
import {restoreCalendarState} from '../calendar/CalendarSlice';
import {restoreAppState} from '../../app/AppSlice';

import { useDispatch } from 'react-redux';
import {setAnalysisFlag, resetAnalysis} from '../analysis/AnalysisSlice';
import {saveStateToLocal} from '../../helpers/saveStateToLocal';
import {selectCompleteState} from '../calendar/CalendarSlice';

function ImportJSON (props) {  
  const state = useSelector(selectCompleteState);
  const appMeta = useSelector(selectApp);
  let [result, setResult] = useState(false);
  let [message, setMessage] = useState('Please upload a data file .cdc');
  const dispatch = useDispatch();

  function openFile(evt) {

    function validateJSON(contents) {
      let result = false;
      let temp = null
      try {
        temp = JSON.parse(contents);
        if (temp["app"].dataCompatible===appMeta.dataCompatible && temp["app"].appName===appMeta.appName) result = true;
      } catch(error) {
        console.error(error);
      }
      if (result) {
        setMessage('Success!')
      } else {
        setMessage('Failed! Something went wrong.')
      }
      setResult(result)
      return result;
    }

    function addFactorsAndIssues(fileContents) {
        const data = JSON.parse(fileContents);
        const factors = data["factors"];
        const issues = data["issues"];
        const calendar = data["calendar"];
        dispatch(restoreFactorState(data["factors"]));
        dispatch(restoreIssueState(data["issues"]));
        dispatch(restoreCalendarState(data["calendar"]));
        dispatch(restoreAppState(data["app"]));
        dispatch(resetAnalysis());

        dispatch(setAnalysisFlag(true));
        localStorage.clear();
        saveStateToLocal(state);

    }

    let status = []; // Status output
      const fileObj = evt.target.files[0]; // We've not allowed multiple files.
      // See https://developer.mozilla.org/en-US/docs/Web/API/FileReader
      const reader = new FileReader(); 
    
      // Defining the function here gives it access to the fileObj constant.
      let fileloaded = e => {
        const fileContents = e.target.result;
        const validFile = validateJSON(fileContents);
        if (validFile) addFactorsAndIssues(fileContents);
        status.push(`File name: "${fileObj.name}". ` +
                    `Length: ${fileContents.length} bytes.`);
        // Show first 80 characters of the file
        const first80char = fileContents.substring(0,80);
        status.push (`First 80 characters of the file:\n${first80char}`)
        // Show the status messages
        // console.log ({status: status.join("\n")});
      }
      
      reader.onload = fileloaded;
      reader.readAsText(fileObj); // read the file
      const fileJSON = reader.result;
      return fileJSON;
    }

  function processFile(evt) {
    let result = openFile(evt);
  }

  return (<div>
    <input type="file" className="hidden"
    multiple={false}
    accept=".cdc"
    onChange={evt => processFile(evt)}
  /><div>
{message}
</div>
  </div>
  )
}

export default ImportJSON;
