import React from 'react';
import { deleteIssue} from '../issue/IssueSlice';
import { deleteIssueFromIndex, deleteIssueFromCalendar } from '../calendar/CalendarSlice';

import { useDispatch } from 'react-redux';

function ManageIssue(props) {
    const issue = props.issue;
    const dispatch = useDispatch();

    function removeIssue(key) {
        dispatch(deleteIssueFromCalendar(key));
        dispatch(deleteIssueFromIndex(key));
        dispatch(deleteIssue(key));
    }
    return (
       <li key={props.issue.key}>
        <div className="float-left"><span>{props.issue.title} </span> </div>
       <div className="float-right"><span  onClick={() => props.setValues({id:issue.key, title:issue.title, desc: issue.desc })}>&#9998;</span>&nbsp;|&nbsp;
       <span  onClick={()=> removeIssue(issue.key)}>&#10007;</span></div>

       </li>
    );
// {payload: {day: theDay, factor: factor}}
}
export default ManageIssue;