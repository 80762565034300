import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  NavLink
} from "react-router-dom";
import Analysis from './components/Analysis';
import Calendar from './components/Calendar';
import UpdateDay from './components/UpdateDay';
import Settings from './components/Settings';
import ScrollCalendar from './components/ScrollCalendar';
import ManageFandI from './components/ManageFandI';
import LandingPage from './components/LandingPage';
import TitlePage from './components/TitlePage';

function App() {;
  return (
    <Router>
      <div>
        <nav id="main-menu">
          <div id="menu-container">
              <NavLink 
                  className="navbar-item"
                  exact activeClassName="navbar-is-active"
                  to="/"><span role="img">&#127974;</span></NavLink>

              <NavLink 
                  className="navbar-item"
                  activeClassName="navbar-is-active"
                  to="/calendar"><span role="img">&#9665;&#128197;&#9655;</span></NavLink>

              <NavLink
                  className="navbar-item"
                  activeClassName="navbar-is-active"              
                to="/scrollcalendar"><span role="img">&#9651;&#128197;&#9661;</span></NavLink>

              <NavLink
                  className="navbar-item"
                  activeClassName="navbar-is-active"              
                to="/manage"><span role="img">&#128203;</span></NavLink> 

              <NavLink
                  className="navbar-item"
                  activeClassName="navbar-is-active"
                  to="/analysis"><span role="img">&#128202;</span></NavLink>

              <NavLink
                  className="navbar-item"
                  activeClassName="navbar-is-active"              
                to="/settings"><span role="img">&#128736;</span></NavLink>
          </div>
        </nav>
        <div id="main-area">
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
        <Route exact path="/">
            <LandingPage />
          </Route>
          <Route path="/analysis">
            <Analysis />
          </Route>
          <Route path="/calendar">
            <Calendar />
          </Route>
          <Route path="/scrollcalendar">
            <ScrollCalendar />
          </Route>
          <Route path="/manage">
            <ManageFandI />
          </Route>
          <Route path="/update_day">
            <UpdateDay />
          </Route>
          <Route path="/settings">
            <Settings />
          </Route>  
          <Route path="/titlepage">
            <TitlePage />
          </Route>         
        </Switch>
        </div>
      </div>
      <footer>
      Copyright 2021 CatDogCar.com
      </footer>
    </Router>

  );
}

export default App;
