import React from 'react';
import DayFactor from './DayFactor';
import DayIssue from './DayIssue';
import { useSelector } from 'react-redux';
import {selectFactors,} from '../factor/factorSlice';
import {selectIssues} from '../issue/IssueSlice';
import nameOfDays from '../../helpers/nameOfDays';

function Day(props) {
//    let myToday = (today.full===props.aDay.date)? "TODAY":"";
    const currentAnalysis = (props.currentAnalysis===undefined)? {}:props.currentAnalysis;
    const factors = useSelector(selectFactors);
    const issues = useSelector(selectIssues);


    let styleDay = (!props.aDay.isActive)? "inactive day": "active day"; 
    const myFactors = props.aDay.myFactors;
    const myIssues = props.aDay.myIssues;

    const linkAnchor = "a"+props.date;

    const listFactors = myFactors.map((key) => {
        let corr = currentAnalysis[key];
        return (<DayFactor
            corr = {corr}
            key = {key} 
            factor = {factors[key]}
        />)
    });
    const listIssues = myIssues.map((key) => {
        let isSelectedIssue = (props.selectedIssue===undefined || props.selectedIssue===null || props.selectedIssue.key !== key)? false:true;

 //       let corr = currentAnalysis[key]; // ===undefined)? currentAnalysis[anIssue.key]:NaN;
        return (
            <DayIssue
            isSelectedIssue = {isSelectedIssue}
            key = {key} 
            issue = {issues[key]}
        />)
    });
    return (
        <div id={linkAnchor} className={styleDay}  onClick={()=> props.calendarClick(props.aDay)}>
        <ul className="listissues">{listIssues}</ul>        
        <div className="date-format"> {nameOfDays(props.aDay.title)} {props.niceDate}</div> 

        <ul className="listfactors">{listFactors}</ul>
        </div> 
    )
}

export default Day;