import React, {useEffect} from 'react';
import { useSelector} from 'react-redux';

import {selectFactors} from '../factor/factorSlice';
import {selectIssues} from '../issue/IssueSlice';
import {selectApp} from '../../app/AppSlice';
import {selectToday} from '../calendar/TemporalSlice';

function ExportJSON () {
  const today = useSelector(selectToday);
  const factors = useSelector(selectFactors);
  const issues = useSelector(selectIssues);
  const app = useSelector(selectApp);

  let output = {}
  output.factors = factors;
  output.issues = issues;
  output.app = app;
  // let formatedData = {export: output};
  let formatedData = JSON.stringify(output);

  useEffect(() => {    // Update the document title using the browser API    
    let data = new Blob([formatedData]);
    let url = window.URL.createObjectURL(data);
    document.getElementById('download_JSON').href = url;
  });

  const downloadName = "CDC-shareF_I-"+today.full +".json"
  return (<div>
    <a id="download_JSON" download={downloadName}>Download (issues and factors)</a><br />
    </div>

  )
}

export default ExportJSON;