import React from 'react';
import {selectIssues} from './issue/IssueSlice';
import {selectFactors,} from './factor/factorSlice';
import { selectActiveDays, selectFactorDayIndex, selectIssueDayIndex } from './calendar/CalendarSlice';
import {selectAnalysisTable, selectSelectedIssue, setComleteAnalysisTable, selectSelectedFactor, selectAnalysisFlag, setAnalysisFlag  } from './analysis/AnalysisSlice';
import { useSelector, useDispatch } from 'react-redux';
import SelectIssue from './analysis/SelectIssue';
import DisplayFactorWithHeat from './analysis/DisplayFactorWithHeat';
import analysisEngine from './analysis/AnalysisEngine';

import PartialCalendar from './analysis/PartialCalendar';
import codeCorr from '../helpers/codeCorr';

function Analysis  (props) { 
  let selectedIssue =  useSelector(selectSelectedIssue);  // initiliased as null
  let selectedFactor =  useSelector(selectSelectedFactor);  // initiliased as null
  let analysisTable = useSelector(selectAnalysisTable);

  const issues = useSelector(selectIssues);
  const factors = useSelector(selectFactors);
  const activeDays = useSelector(selectActiveDays);
  const factorDayIndex = useSelector(selectFactorDayIndex);
  const issueDayIndex = useSelector(selectIssueDayIndex);

  const dispatch = useDispatch();

  const generateNewStats = useSelector(selectAnalysisFlag);
  if (generateNewStats) {
    dispatch(setAnalysisFlag(false));
    const result = analysisEngine.generateCompleteAnalyisTable(issues, factors, activeDays);
    dispatch(setComleteAnalysisTable({result}));
  } 

  function  isIssueFactorInAnalysisTable(selectedIssue, selectedFactor, analysisTable) {
    if (selectedIssue===null || selectedFactor ===null) return false
    if (selectedIssue.key===undefined || selectedFactor.key===undefined || analysisTable ===null || analysisTable[selectedIssue.key]=== null ) return false;
    if (analysisTable[selectedIssue.key][selectedFactor.key] ===undefined) return false;
    return true;
  }

  let stats = {corr:NaN, phi:[0,0,0,0]};
  let currentAnalysis = null;
  if (isIssueFactorInAnalysisTable(selectedIssue, selectedFactor, analysisTable)) { 
      currentAnalysis = analysisTable[selectedIssue.key];
      stats = currentAnalysis[selectedFactor.key];
  };



  let selFlag = false;
  const listIssues = Object.keys(issues).map((key)=> {
    
    selFlag = (selectedIssue!==null && issues[key].key === selectedIssue.key)? true:false;
    return (<SelectIssue 
      key = {key}
      selFlag = {selFlag}
      issue = {issues[key]} 
    />)
  });

  const listFactors = Object.keys(factors).map((key) => {
    selFlag = (selectedFactor!==null && factors[key].key === selectedFactor.key)? true:false;
    let corr= NaN;

    if (currentAnalysis!==null) {
      corr = currentAnalysis[key]; 
    }
      return (<DisplayFactorWithHeat 
          key = {key}
          selFlag = {selFlag}
          corr = {corr}
          factor = {factors[key]} 
//          factorState= {factorState}
 //         setFactorState = {setFactorState}
      />)
      });
      let displayIssue = (selectedIssue!==null && selectedIssue.title !== undefined)? selectedIssue.title: 'issue';
      let displayFactor = (selectedFactor!==null && selectedFactor.title !== undefined)? selectedFactor.title: 'factor';

    let combinedClass= "analysis-grid-item "+codeCorr(stats.reliability);
  return (
    <section id="analysis">  

    <div id="analysis-big-grid">
    <div className={combinedClass}>
      <div>
        Reliability - Are your results valid?< br/> 
    key: <span className="pos0">+</span><span className="pos1">+</span><span className="pos2">+</span>
        <span className="pos3">+</span><span className="pos4">+</span><span className="pos5">+</span> <br />
      <div className="tooltip" >&#9432;<span className="tooltiptext">This is number of entries/23. I'm looking for a more sophisticated technique.</span></div>
      </div>
      </div>
      <div className="analysis-grid-item"> {listIssues}
        </div>

    <div className="analysis-grid-item">
      <ul className="analysis-factor-list">
      key: <span className="pos0">+</span><span className="pos1">+</span><span className="pos2">+</span>
        <span className="pos3">+</span><span className="pos4">+</span><span className="pos5">+</span> 
      {listFactors}
      </ul>
        </div>
        <div className="analysis-grid-item">
    <div id="analysis-figures-grid">
      <div className="analysis-grid-item"><span className="analysis-desc">{displayIssue} &#10004;<br />{displayFactor} &#10004;</span><br />{stats.phi[0]}</div>
      <div className="analysis-grid-item"><span className="analysis-desc">{displayIssue} &#10006;<br />{displayFactor} &#10004;</span><br />{stats.phi[1]}</div>
      <div className="analysis-grid-item"><span className="analysis-desc">{displayIssue} &#10004;<br />{displayFactor} &#10006;</span><br />{stats.phi[2]}</div>  
      <div className="analysis-grid-item"><span className="analysis-desc">{displayIssue} &#10006;<br />{displayFactor} &#10006;</span><br />{stats.phi[3]}</div>
    </div>
</div>
    </div>
    {currentAnalysis && selectedIssue && issueDayIndex[selectedIssue.key]!==undefined ? (
      <PartialCalendar
          setOfDays= {issueDayIndex[selectedIssue.key]}
          currentAnalysis = {currentAnalysis}
          selectedIssue = {selectedIssue}
      />
    ) : (
        <div>Select a factor</div>
      )}
    </section>
    );
}
export default Analysis;