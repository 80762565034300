import React from 'react';
// import codeCorr from '../../helpers/codeCorr';

function DayIssue(props) {
    //    console.log('key', props.key);
//    let corr = (props.corr===undefined)? NaN: props.corr.corr;
//        return <li className={codeCorr(corr)}>{props.issue.title}</li>

let isSelectedIssue = (props.isSelectedIssue===undefined || props.isSelectedIssue!==true)? '':'is-selected-issue';
return <li className={isSelectedIssue}>{props.issue.title}</li>
    }

export default DayIssue;