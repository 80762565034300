import React from 'react';
let months = [
  'January', 'February', 'March', 'April', 'May',
  'June', 'July', 'August', 'September',
  'October', 'November', 'December'
  ];


export default function  MonthSelector(props) {
    return (
         <span>{ !(props.currentView.month===props.startDate.month && props.currentView.year===props.startDate.year) && (<span onClick={() => props.clickMonthUp('down')}>&#9665; </span>)}
           <span className="date-format">{months[props.currentView.month-1]} {props.currentView.year}</span> 
           { !(props.currentView.month===props.today.month && props.currentView.year===props.today.year) && (<span onClick={() => props.clickMonthUp('up')}> &#9655;</span>)}
           </span>
)}