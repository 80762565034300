import React from 'react';
// import FormatDate from '../calendar/FormatDate';
import { useSelector, useDispatch } from 'react-redux';
import Day from '../calendar/Day';
import {useHistory} from 'react-router-dom';
import {addNewActiveDay, selectActiveDays} from '../calendar/CalendarSlice';


function PartialCalendar (props) {
    const setOfDays = props.setOfDays;
    const currentAnalysis = props.currentAnalysis;
    const selectedIssue = props.selectedIssue;
    const history = useHistory();
    const dispatch = useDispatch();
    const activeDays = useSelector(selectActiveDays);

  //  let formatDate = FormatDate({year:y, month:m});
  const listDays = [];
    const sortedDates = [...setOfDays].sort();
    sortedDates.forEach((date) => {
        let dayObj = activeDays[date];
        let dayValue = dayObj.id;
        let niceDate = dayObj.id;
/*
    let dayObj ={id: dayValue, title: titleNo, myIssues : [],myFactors: []};
    if (activeDays[dayValue]!== undefined) {
        dayObj = activeDays[dayValue] 
    } 
*/
        const calendarClick = () => {
               dispatch(addNewActiveDay({today: dayObj}));
               //dispatch(setSelectedDay({today: dayValue}));
               let updatedPath = history.location.pathname+'#a'+dayValue;
               // history.replace(updatedPath);
               history.push('/scrollcalendar#a'+dayValue);
           };

              listDays.push (
                  (
                  <Day
                  key = {dayValue} 
                  calendarClick= {onClick => calendarClick()}
                  niceDate = {niceDate}
                  date = {dayValue}
                  aDay = {dayObj} 
                  currentAnalysis = {currentAnalysis}
                  selectedIssue = {selectedIssue}
                  //selectedDay = {selectedDay}
                  //removeFactorIssue = {removeFactorIssue}
                  /> )
               );
           // titleNo = (titleNo===0)? 6:titleNo-1;
       });
       return (<div>

   <section id="analysis-week-view">{listDays }</section>
   </div>
)
}

    export default PartialCalendar;