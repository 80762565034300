let months = [
    'January', 'February', 'March', 'April', 'May',
    'June', 'July', 'August', 'September',
    'October', 'November', 'December'
    ];

function FormatDate(currentView) {
    return function(i) {
        let month = months[currentView.month-1];
        let mm = String(currentView.month).padStart(2, '0'); //January is 0!
        let dd = String(i).padStart(2, '0'); 
        let dayValue =currentView.year+'-'+ mm +'-'+dd;
        let niceDate = dd+" "+month+" "+currentView.year;
        return [dayValue, niceDate];
    }
}

export default FormatDate;
