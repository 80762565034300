import React from 'react';
import Issue from '../issue/Issue';
import {selectIssues} from '../issue/IssueSlice';
import { useSelector} from 'react-redux';

function MobileIssueBox(props)  {

    const issues = useSelector(selectIssues);
    
    const listIssues = Object.keys(issues).map((key) =>
        <Issue 
            key = {key}
            issue = {issues[key]} 
            theDay = {props.theDay}
            today = {props.selDay}
        />
    );

    return (
        <div>
            <ul className="factor-issue-list">{listIssues}</ul>
            {/*     <AddFactor /> */ }
        </div>
    );   
}

export default MobileIssueBox;