import React from 'react';

function UpdateFactor(props) {
        return (
        <li>
        <div className="float-left">{props.factor.title} </div>
            <div className="float-right"><span className="removebutton" onClick = {() => props.removeItem(props.factor)} >&#10007;</span></div>
        </li>
            )
    }

export default UpdateFactor;