import React from 'react';
import { useDispatch } from 'react-redux';
import { setIssue, setFactorsForIssue } from './AnalysisSlice';
import analysisEngine from './AnalysisEngine';
import {selectFactors} from '../factor/factorSlice';
import { useSelector } from 'react-redux';
import { selectActiveDays } from '../calendar/CalendarSlice';
import codeCorr from '../../helpers/codeCorr';

function Issue(props) {
    const dispatch = useDispatch();
    const factors = useSelector(selectFactors);
    const activeDays = useSelector(selectActiveDays);
    const issue = props.issue;
    let selFlag = (props.selFlag)? "sel-analysis":"";
    let corr = (props.corr===undefined)? NaN: props.corr.corr;


    return (
       <li key={props.issue.key} className={codeCorr(corr), selFlag} onClick={() => {
            dispatch(setIssue({issue}));
            const result = analysisEngine.calculateCorrelations(issue, factors, activeDays); 
            dispatch(setFactorsForIssue({issue, result}));
            }
        }><span>
       {props.issue.title} </span> 
       </li>
    );
}
export default Issue;