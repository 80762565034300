import { configureStore } from '@reduxjs/toolkit';
import factorReducer from '../components/factor/factorSlice';
import calendarReducer from '../components/calendar/CalendarSlice'
import issueReducer from '../components/issue/IssueSlice';
import analysisReducer  from '../components/analysis/AnalysisSlice';
import temporalReducer from '../components/calendar/TemporalSlice';
import appSlice from './AppSlice';

const store = (preloadValues={}) => {
  return  configureStore({
    reducer: {
      app: appSlice,
      factors: factorReducer,
      issues: issueReducer,
      calendar:calendarReducer,
      analysis: analysisReducer,
      temporal: temporalReducer,
    },
    preloadedState: preloadValues
  })
};

export default store;
