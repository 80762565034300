import React from 'react'
import ExportCSV from '../components/settings/ExportCSV';
import SaveState from './settings/saveState';
import LoadState from './settings/loadState';
import {resetFactor} from './factor/factorSlice';
import {resetIssue} from './issue/IssueSlice';
import {resetCalendar} from './calendar/CalendarSlice';
import {resetApp} from './../app/AppSlice';
import { useDispatch } from 'react-redux';
import {resetAnalysis, setAnalysisFlag} from './analysis/AnalysisSlice';
import {saveStateToLocal} from './../helpers/saveStateToLocal';

export function Settings() { 
  const dispatch = useDispatch();
  return (
    <section id="settings">

        <h3>Settings Beta 0.2</h3>
        Calendar asc/desc [desc]<br />
        Storage local/cloud [local] DON'T DELETE THE SITE CACHE!!!<br/>
        Include days in the date range which are not selected? Yes/No [No]<br />
        <hr />
        <h3>Backup -&gt; move to another device</h3>

          Load
          <LoadState /><br />   

          Save
          <SaveState /><br />

      <hr />
      <h3>Export data</h3>
        <ExportCSV />
        <hr></hr>
        <h2>Your data is stored on your device: laptop or phone. If you click below you will erase it. Beware!</h2>
      <button onClick={() => {
        
                dispatch(resetFactor());
                dispatch(resetIssue());
                dispatch(resetCalendar());
                dispatch(resetApp());
                dispatch(resetAnalysis());
                saveStateToLocal();
        }}>Clear All!!!</button>
    <div></div>
    </section>
    )
}
export default Settings;