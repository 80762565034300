import React, {useEffect} from 'react';
import FormatDate from '../../helpers/FormatDate';
import { useSelector} from 'react-redux';
import {selectActiveDays, selectStartDate} from '../calendar/CalendarSlice';
import {selectToday} from '../calendar/TemporalSlice';

import {selectFactors} from '../factor/factorSlice';
import {selectIssues} from '../issue/IssueSlice';

import years from '../../helpers/years';
import nameOfDays from '../../helpers/nameOfDays';
import newFormtDate from '../../helpers/newDateFormat';

function ExportCSV (props) {
  const today = useSelector(selectToday);
  const factors = useSelector(selectFactors);
  const issues = useSelector(selectIssues);
  const activeDays = useSelector(selectActiveDays);
  const startDate = useSelector(selectStartDate);
  
  const listDays = [];
  const headings = ['"Date"', '"Day"'];
  
  function cleanStringForCsv(value) {
    let innerValue = (value === null) ? '' : value;
    let result = innerValue.replace(/"/g, '""');
    return result;
  }

  let listIssues = [];
  let typeIssues = [];
  let issueMap = {};

  Object.keys(issues).forEach((key, i) => {
    listIssues.push(JSON.stringify(issues[key].title));
    issueMap[key] =i;

    typeIssues.push('Issue');
  });

 let listFactors = [];
 let typeFactors = [];
  let factorMap = {};

  Object.keys(factors).forEach((key, i) => {
    listFactors.push(JSON.stringify(factors[key].title));
    factorMap[key] = i;
    typeFactors.push('Factor');
  });

  const listTypes = [];
  listTypes.push(headings); 
  listTypes.push(...typeIssues);
  listTypes.push(...typeFactors);
  listTypes.push('\n'); 
  listDays.push(listTypes);

  const listHeadings = [];
  listHeadings.push(headings);
  listHeadings.push(...listIssues);
  listHeadings.push(...listFactors);
  listHeadings.push('\n'); 
  listDays.push(listHeadings);


  const numOfIssues = Object.keys(issues).length;
  const numOfFactors =  Object.keys(factors).length;

/*
  for (let y=startDate.year; y<=today.year;y++) {
    let mons = (startDate.year === today.year)? today.month:12; 
    for (let m=mons; m>=1;m--) {
        let formatDate = FormatDate({year:y, month:m});
        let days = (today.year === y && today.month===m)? today.day:years[y][m-1].days; 
        for (let d=days; d>=1;d--) {
*/

          let now = new Date();
          for (let d = now; d>=new Date(startDate.year,startDate.month,1); d.setDate(d.getDate() - 1)) {
              let currentDate = new Date(d);
              let [dayValue, niceDate] = newFormtDate(currentDate);
              let titleNo = currentDate.getDay();
       //       let dayObj =(activeDays[dayValue]!== undefined)? activeDays[dayValue] :{id: dayValue, title: titleNo, myIssues : [],myFactors: []};
  

     //       let [dayValue] = formatDate(d);


            let blankFactors = new Array(numOfFactors).fill("");
            let blankIssues = new Array(numOfIssues).fill("");
            let dayArray = [];

            dayArray.push(dayValue);

            if (activeDays[dayValue]!== undefined) {
              let dayObj = activeDays[dayValue];
              dayArray.push(nameOfDays(dayObj.title));
                dayObj.myFactors.forEach((factor) => {
                  blankFactors[factorMap[factor]] = true;
                })
                dayObj.myIssues.forEach((issue) => {
                  blankIssues[issueMap[issue]] = true;
                })
              } 
            dayArray.push(...blankIssues);
            dayArray.push(...blankFactors);
            dayArray.push('\n')
            listDays.push ( dayArray );
        }
      
  
    

  let formatedData = listDays;



  useEffect(() => {    // Update the document title using the browser API    
    let data = new Blob(formatedData, {type: 'text/plain'});
    let url = window.URL.createObjectURL(data);
    document.getElementById('download_linkz').href = url;
  });

  const downloadName = "CDC-export-"+today.full +".csv"
return (<div>
    <a id="download_linkz" download={downloadName}>Download as CSV File (Excel)</a><br />
    </div>

  )
}

export default ExportCSV;