import { createSlice } from '@reduxjs/toolkit';

export const calendarSlice = createSlice({
  name: 'calendar',
  initialState:  {
    activeDays: {},  // {'date':{id: date, title: title, myIssues : [],myFactors: []},nextDate:{}
    startDate: {year:2020, month:1},
    factorDayIndex: {},
    issueDayIndex: {},
  },
  reducers: {
    resetCalendar: (state,action) =>{
      state.activeDays = {};  // {'date':{id: date, title: title, myIssues : [],myFactors: []},nextDate:{}
      state.startDate = {year:2020, month:1};
      state.factorDayIndex = {};
      state.issueDayIndex = {};
    },
    addNewActiveDay: (state, action) => {
      const newDay = action.payload.today;
      if (state.activeDays[newDay.id]===undefined) {
        state.activeDays[newDay.id] =  
            {id: newDay.id,
            title : newDay.title,
            myFactors: [],
            myIssues: [],
            isActive: true,
          };
        }
    },
    restoreCalendarState: (state, action) => {
      state.activeDays = action.payload.activeDays;
      state.startDate = action.payload.startDate;
      state.factorDayIndex = action.payload.factorDayIndex;
      state.issueDayIndex= action.payload.issueDayIndex;
    },
    addFactorToDay: (state, action) => {
      const newDate = action.payload.today;
      const factor = action.payload.factor;
      const myFactors = state.activeDays[newDate].myFactors;
      let addFactor =true;
      myFactors.forEach((myFactor) => {
        if (myFactor===factor.key)  addFactor =false;
      });
      if (addFactor) {
        myFactors.push(factor.key);
        if (state.factorDayIndex[factor.key]===undefined) state.factorDayIndex[factor.key]=[];
        state.factorDayIndex[factor.key].push (newDate);
      }
    },
    addIssueToDay: (state, action) => {
      const newDate = action.payload.today;
      const issue = action.payload.issue;
      const myIssues = state.activeDays[newDate].myIssues;
      let addIssue =true;
      myIssues.forEach((myIssue) => {
        if (myIssue===issue.key)  addIssue =false;
      });
      if (addIssue) {
        myIssues.push(issue.key);
        if (state.issueDayIndex[issue.key]===undefined) state.issueDayIndex[issue.key]=[];
        state.issueDayIndex[issue.key].push (newDate);
      }
    },

    removeFactorFromDay: (state, action) => {
      const day = action.payload.day.id;
      const factor = action.payload.factor;
      let newFactors = state.activeDays[day].myFactors.filter((fact) =>{
        return fact!==factor.key
      });
      state.activeDays[day].myFactors = newFactors;
      const targ = state.factorDayIndex[factor.key];
      const index = targ.indexOf(day);
      if (index > -1) {
        targ.splice(index, 1);
      }
    },
    removeIssueFromDay: (state, action) => {
      const day = action.payload.day.id;
      const issue = action.payload.issue;
      let newIssues = state.activeDays[day].myIssues.filter((issu) =>{
        return issu!==issue.key
      });
      state.activeDays[day].myIssues = newIssues;
    },    
    deleteActiveDay:(state,action) => {
      delete state.activeDays[action.payload.id];
    },

    setCurrentView: (state, action) => {
      state.currentView=action.payload.newView;
    },
    deleteFactorFromIndex: (state,action) => {
      delete state.factorDayIndex[action.payload];
    },
    deleteFactorFromCalendar: (state,action) => {
      const key = action.payload;
      if (state.factorDayIndex[key]!==undefined) {
        state.factorDayIndex[key].forEach( date => {
            let index = state.activeDays[date].myFactors.indexOf(key);
            if (index > -1) {
              state.activeDays[date].myFactors.splice(index, 1);
            }
        });
      }
    },
    deleteIssueFromIndex: (state,action) => {
      delete state.issueDayIndex[action.payload];
    },
    deleteIssueFromCalendar: (state,action) => {
      const key = action.payload;
      if (state.issueDayIndex[key]!==undefined) {
        state.issueDayIndex[key].forEach( date => {
            let index = state.activeDays[date].myIssues.indexOf(key);
            if (index > -1) {
              state.activeDays[date].myIssues.splice(index, 1);
            }
        });
      }
    }
  },
});

export const { resetCalendar, restoreCalendarState, addFactorToDay, removeFactor, addNewActiveDay, addIssueToDay, 
    setCurrentView, deleteActiveDay, removeFactorFromDay, removeIssueFromDay, deleteFactorFromIndex, deleteFactorFromCalendar, deleteIssueFromIndex, deleteIssueFromCalendar} = calendarSlice.actions;

export const selectActiveDays = state => state.calendar.activeDays;
export const selectCalendarState = state => state.calendar;

export const selectToday = state => state.calendar.today;
export const selectSelectedDay = state=> state.calendar.selectedDay;
export let selectDay = (state, date) => {return state.calendar[date]};
export const selectCurrentView =  state => {return state.calendar.currentView};
export const selectCompleteState =  state => state;
export const selectStartDate =  state => state.calendar.startDate;
export const selectFactorDayIndex = state => state.calendar.factorDayIndex;
export const selectIssueDayIndex = state => state.calendar.issueDayIndex;


export default calendarSlice.reducer;