import React from 'react';
import { useDispatch } from 'react-redux';
import { addIssueToDay } from '../calendar/CalendarSlice';
import {setAnalysisFlag} from '../analysis/AnalysisSlice';

function Issue(props) {
    const dispatch = useDispatch();
    const today = props.today;
    const issue = props.issue;

    return (
       <li key={props.issue.key} onClick={() =>{
            dispatch(addIssueToDay({today, issue}));
            dispatch(setAnalysisFlag(true))}}><span>
       {props.issue.title} </span> 
       </li>
    );
// {payload: {day: theDay, factor: factor}}
}
export default Issue;