const today = function getToday() {
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    let times = {
        full: yyyy+'-'+ mm +'-' + dd,
        month : Number(mm),
        year : yyyy,
        day: Number(dd)
    }
    return  times;
}

export default today;