import React, {useEffect, useState} from 'react';
import { useSelector} from 'react-redux';
import {selectApp} from '../../app/AppSlice';
import {addUpdateFactor, factorSlice} from '../factor/factorSlice';
import {addUpdateIssue} from '../issue/IssueSlice';
import { useDispatch } from 'react-redux';

function ImportJSON (props) {  
  const appMeta = useSelector(selectApp);
  let [result, setResult] = useState(false);
  let [message, setMessage] = useState('Please upload a data file .json');
  const dispatch = useDispatch();

  function openFile(evt) {

    function validateJSON(contents) {
      let result = false;
      let temp = null
      try {
        temp = JSON.parse(contents);
        if (temp["app"].dataCompatible===appMeta.dataCompatible && temp["app"].appName===appMeta.appName) result = true;
      } catch(error) {
        console.error(error);
      }
      if (result) {
        setMessage('Success!')
      } else {
        setMessage('Failed! Something went wrong.')
      }
      setResult(result)
      return result;
    }

    function addFactorsAndIssues(fileContents) {
        const data = JSON.parse(fileContents);
        const factors = data["factors"];
        const issues = data["issues"];
        Object.keys(factors).forEach((key)=> {
          let obj = {id: null, title:factors[key].title, desc: factors[key].desc};
          dispatch(addUpdateFactor(obj));
        });
        Object.keys(issues).forEach((key)=> {
          let obj = {id: null, title:issues[key].title, desc: issues[key].desc};
          dispatch(addUpdateIssue(obj));
        });
      }

    let status = []; // Status output
      const fileObj = evt.target.files[0]; // We've not allowed multiple files.
      // See https://developer.mozilla.org/en-US/docs/Web/API/FileReader
      const reader = new FileReader(); 
    
      // Defining the function here gives it access to the fileObj constant.
      let fileloaded = e => {
        const fileContents = e.target.result;
        const validFile = validateJSON(fileContents);
        if (validFile) addFactorsAndIssues(fileContents);
        status.push(`File name: "${fileObj.name}". ` +
                    `Length: ${fileContents.length} bytes.`);
        // Show first 80 characters of the file
        const first80char = fileContents.substring(0,80);
        status.push (`First 80 characters of the file:\n${first80char}`)
        // Show the status messages
        console.log ({status: status.join("\n")});
      }
      
      reader.onload = fileloaded;
      reader.readAsText(fileObj); // read the file
      const fileJSON = reader.result;
      return fileJSON;
    }

  function processFile(evt) {
    let result = openFile(evt);
  }

  return (<div>
    <input type="file" className="hidden"
    multiple={false}
    accept=".json"
    onChange={evt => processFile(evt)}
  /><div>
{message}
</div>
  </div>
  )
}

export default ImportJSON;
