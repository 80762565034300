import React from 'react';


function TitlePage  (props) { 
return (
    <section id="title-page">  
        <div class="main"> CatDogCar.com</div>
        <div class="by">The coincidence calculator and calendar</div>
</section>
)}

export default TitlePage;