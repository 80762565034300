import { createSlice } from '@reduxjs/toolkit';
import today from '../helpers/today';

export const appSlice = createSlice({
  name: 'app',
  initialState:  {
    appName: 'catDogCar.com',
    version: 0.2,
    dataCompatible:0.2,
    initialisationDate: today().full,
  },
  reducers: {
    resetApp: (state, action) => {
      state.appName= 'catDogCar.com';
      state.version= 0.2;
      state.dataCompatible=0.2;
      state.initialisationDate= today().full;  
    },
    restoreAppState: (state, action) => {
      state.appName = action.payload.appName;
      state.version = action.payload.version;
      state.dataCompatible = action.payload.dataCompatible;
      state.initialisationDate= action.payload.initialisationDate;
    },
  },
});

export const { restoreAppState, resetApp} = appSlice.actions;


export const selectApp = state => state.app;

export default appSlice.reducer;