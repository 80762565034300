import React, {useState, useEffect} from 'react';
import {addUpdateFactor} from '../factor/factorSlice';
import {addUpdateIssue} from '../issue/IssueSlice';
import { useDispatch } from 'react-redux';

export function AddValues(props) {
  let [value, setValue] = useState('');
  let [desc, setDesc] = useState('');
  let [id, setId] = useState(null);
  const dispatch = useDispatch('');

  useEffect(() => {
    setDesc(props.values.desc)
    setValue(props.values.title);
    setId(props.values.id);
  }, [props]);

      const handleChangeValue=(event) =>{
        setValue(event.target.value);
      }
      const handleChangeDesc =(event) =>{
        setDesc(event.target.value);
      }
      const handleReset =(event) =>{
        setValue('');
        setDesc('');
        setId(null);
      }

      const handleSubmit = (event) =>{
        if (value!=='') {
          let capTitle = value.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())));
          if (props.addType==='factor') {
            dispatch(addUpdateFactor({title: capTitle, desc, id}));
          }
          if (props.addType==='issue') {
            dispatch(addUpdateIssue({title: capTitle, desc, id}));
          }
          event.preventDefault();
          props.setValues({id:null, title: '', desc: ''});
        } else {
          event.preventDefault();
        }
      }
        return (<div>
            <form onSubmit={handleSubmit}>
                  <label>Title<br /> <input name="value" value={value}  onChange={handleChangeValue} maxLength="18" placeholder="Max 18 chars" size="16" /></label><br />
                  <label>Desc<br /> <textarea name="desc" value={desc}  onChange={handleChangeDesc} rows="4" cols="16"/></label><br />
                  <input type="submit" value={props.submitText} />
            </form><button onClick={handleReset}>reset</button>
            </div>
            )
}

export default AddValues;