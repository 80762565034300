import React, { useState, useEffect  } from 'react';
import {selectCompleteState} from './calendar/CalendarSlice';
import {selectSelectedDay} from './calendar/TemporalSlice';

import { useSelector, useDispatch } from 'react-redux';
import MobileFactorBox from './updateDay/MobileFactorBox';
import MobileIssueBox from './updateDay/MobileIssueBox';
import {setAnalysisFlag} from './analysis/AnalysisSlice';
import UpdateDayContainer from './updateDay/UpdateDayContainer';
import { useHistory, Redirect } from 'react-router-dom'
import FormatDate from '../helpers/FormatDate';
import {saveStateToLocal} from '../helpers/saveStateToLocal';

function UpdateDay() {
    let [isDeletedDay, setDeletedDay] = useState(false);

    let [isFactorsShowing, setIsFactorsShowing] = useState(false);
    const selDay = useSelector(selectSelectedDay);
    const history = useHistory();
    let dayObj = useSelector(state => state.calendar.activeDays[selDay]);
    const dispatch = useDispatch();
    
    const dateElements = selDay.split("-");
    const formatDate = FormatDate({year: dateElements[0], month: dateElements[1]});
    const niceDate = formatDate(dateElements[2]);

    const state = useSelector(selectCompleteState);
    
    useEffect(() => {
        return () => {
                try {
                    saveStateToLocal(state);
                } catch (e) {
                  console.warn(e);
                }
            }
      }, [state]);

    if (dayObj===undefined && isDeletedDay===false) { return (<Redirect to="/" />); } // throwing error messages! return (<div></div>); - good for direct navigation fix + but not for delete update_day
    if (isDeletedDay) { history.go(-1); return (<div></div>)}
    return (
        <section id="update-day"> 
            <div className="grid-container">
                <div className="grid-item">
                <UpdateDayContainer
                        key = {selDay} 
                        niceDate ={niceDate[1]}
                        date = {selDay}
                        aDay = {dayObj} 
                        setDeletedDay ={setDeletedDay}
                />  
                </div>
                <div className="grid-item">
                {!isFactorsShowing ? (
        <section id="issues">
            <h3>Issues <span className="unselected" 
            onClick={()=> setIsFactorsShowing(!isFactorsShowing) }>Factors</span></h3>
            <MobileIssueBox 
                        selDay = {selDay}   
                        theDay = {dayObj}   
                    />
      
        </section>

            ) : (
        
        <section id="factors">
        <h3><span className="unselected" onClick={()=> setIsFactorsShowing(!isFactorsShowing) }>Issues</span> Factors</h3>
        <MobileFactorBox 
                        selDay = {selDay}   
                        theDay = {dayObj}   
                    />

        </section>

    )}
                </div>
            </div>
    </section>
    );

}

export default UpdateDay;