import React from 'react';
import { useDispatch } from 'react-redux';
import { addFactorToDay } from '../calendar/CalendarSlice';
import {setAnalysisFlag} from '../analysis/AnalysisSlice';


function Factor(props) {
    const dispatch = useDispatch();
    const today = props.today;
    const factor = props.factor;

    return (
       <li key={factor.key} onClick={() => {
            dispatch(addFactorToDay({today, factor}));
            dispatch(setAnalysisFlag(true));
            //const result = analysisEngine.calculateCorrelations(issue, factors, activeDays); 
            //dispatch(setFactorsForIssue({issue, result}));    
        }
            }><span>
       {factor.title} </span> 
       </li>
    );
// {payload: {day: theDay, factor: factor}}
}
export default Factor;