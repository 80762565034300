import React from 'react';
import codeCorr from '../../helpers/codeCorr';

function DayFactor(props) {
    let corr = (props.corr===undefined)? NaN: props.corr.corr;

        return (
        <li className={codeCorr(corr)}>
        {props.factor.title}
        </li>
            )
    }

export default DayFactor;