import React from 'react';
// import codeCorr from '../../helpers/codeCorr';

function UpdateIssue(props) {
return <li>
    <div className="float-left">{props.issue.title}</div>
    <div className="float-right"><span className="removebutton" onClick = {() => props.removeItem(props.issue)} >&#10007;</span></div>
</li>
    }

export default UpdateIssue;