const fixFactors = {
    1: {key: 1,
    title: 'Cheese',
    desc: 'the old dry kind'
    },
    2: {key: 2,
    title: 'Red Wine',
    desc: 'especially chianti'},
    3: {key: 3,
    title: 'Smoking',
    desc:''},
    4: {key: 4,
    title: 'Gin',
    desc:''},
    5: {key: 5,
    title: 'Kebab',
    desc:''},
    6: {key: 6,
    title: 'Loud music',
    desc:''},
    7: {key: 7,
    title: 'Flashing lights',
    desc:''
    }
};
export default fixFactors;