import React from 'react';
import Factor from '../factor/Factor';
import {selectFactors,} from '../factor/factorSlice';
import { useSelector } from 'react-redux';

function MobileFactorBox(props)  {

    const factors = useSelector(selectFactors);
    
    const listItems = Object.keys(factors).map((key) =>
        <Factor 
            key = {key}
            factor = {factors[key]} 
            today = {props.selDay}
        />
    );

    return (
        <div>
            <ul className="factor-issue-list">{listItems}</ul>
            {/*     <AddFactor /> */ }
        </div>
    );   
}

export default MobileFactorBox;