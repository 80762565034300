import { createSlice } from '@reduxjs/toolkit';

export const analysisSlice = createSlice({
  name: 'analysis',
  initialState:  {
      analysisFlag: false,
      selectedIssue: null, // analysis_key
      selectedFactor: null, // used to keep global state on the analysis page
      analysisTable: {}, // [ {analysis_key: [ {factor_key: {phiBox, totalDays}}}   phiBox: Array(4).fill(0),  
  },
  
  reducers: {
    resetAnalysis(state, action) {
      state.analysisFlag = false;
      state.selectedIssue = null;
      state.selectedFactor = null;
      state.analysisTable = {};
    },
    addAnalysis(state, action) {
      console.log(action.payload);
      const newanalysis  = action.payload.analysis
      state.selectedAnalysis.push(newanalysis);
    },
    setIssue(state,action) {
      state.selectedIssue=action.payload.issue;
    },
    setFactor(state,action) {
      state.selectedFactor=action.payload.factor;
    },    
    updateCorrelations: (state, action) => {
      const object = action.payload
      console.log(action);
        if (typeof object==="object") {
          let analysis = state.analysis.find( (analysis) => { 
              return analysis===object;
          });
          analysis.title= object.value;
          analysis.desc = object.desc;
      }
      return state;
    },
    setFactorsForIssue: (state, action) => {
      let issue = action.payload.issue;
      if (issue!==null) {
        const result = action.payload.result;
        state.analysisTable[issue.key] = result;
      }
    },
    setComleteAnalysisTable: (state, action) => {
      let result = action.payload.result;
      if (result!==null) {
        state.analysisTable = result;
      }
    },
    setAnalysisFlag: (state, action) => {
      state.analysisFlag = action.payload;
    }

  },
});

export const { resetAnalysis, addAnalysis, setIssue, setFactor, setFactorsForIssue, setComleteAnalysisTable, setAnalysisFlag } = analysisSlice.actions;

export const selectSelectedFactor = state => state.analysis.selectedFactor;
export const selectSelectedIssue = state => state.analysis.selectedIssue;
export const selectAnalysisTable =  state => state.analysis.analysisTable;
export const selectAnalysisFlag=  state => state.analysis.analysisFlag;
export default analysisSlice.reducer;