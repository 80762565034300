import { createSlice } from '@reduxjs/toolkit';
import fixIssues from '../../tests/fixtures/fix-issues.js';

function highestKey(factors) {
  let highest = 1;
  for (const key in factors) {
    if (factors[key].key>highest) highest = factors[key].key
  }
  return highest;
}


export const issueSlice = createSlice({
  name: 'issues',
  initialState: {
    issues: fixIssues,
    lastKey: 0,
  },
  reducers: {
    resetIssue: (state, payload) => {
      state.issues = {1: {key: 1,
        title: 'Issue placeholder',
        desc: 'please delete'
        }}
    },
    restoreIssueState(state,action) {
      state.issues=action.payload;
    },
    addissue(state, action) {
      console.log(action.payload);
      const newIssue  = action.payload.issue
      state.push(newIssue);
    },
    addUpdateIssue(state, action) {
      const newIssue  = action.payload;
      let lastKey = null
      // does it have a key? if so save over, or use the next highestkey
      if (newIssue.id===null) {
        lastKey = highestKey(state.issues);
        lastKey++;
        state.lastKey=lastKey;
        newIssue.key=lastKey;
      } else {
        lastKey = newIssue.id
        newIssue.key = lastKey
      }
      delete newIssue.id;
      state.issues[lastKey] = newIssue;
    },

    deleteIssue: (state, action) => {
      const id = action.payload
      delete state.issues[id];
    },
  },
});
export const { resetIssue, restoreIssueState, addissue, addUpdateIssue, deleteIssue } = issueSlice.actions;


export const selectIssues = state => state.issues.issues;


export default issueSlice.reducer;