
export default function codeCorr (corr) {
        let value = "pos0";

        switch (true) {
            case (corr>0.7):
                value ="pos5"
            break;
            case (corr>0.4):
                value="pos4";
            break;
            case (corr>0.3):
                value="pos3";
            break;
            case (corr>0.2):
                value="pos2";
            break;
            case (corr>0.1):
                value="pos1";
            break;
            case (corr>0):
                value="pos0";
            break;
            case (corr>-0.1):
                value ="min1"
            break;
            case (corr>-0.2):
                value="min2";
            break;
            case (corr>-0.3):
                value="min3";
            break;
            case (corr>-0.4):
                value="min4";
            break;
            case (corr>-0.7):
                value="min5";
            break;
            default:
                value ="pos0";
            break;
        }

        return value;

    }

