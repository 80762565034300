const  minNumOfEntries =23;

class AnalysisEngine {

  static instance = null;
  static createInstance() {
      var object = new AnalysisEngine();
      return object;
  }

  static getInstance () {
      if (!AnalysisEngine.instance) {
        AnalysisEngine.instance = AnalysisEngine.createInstance();
      }
      return AnalysisEngine.instance;
  }

  hasRelationship(parents, child) {
    let result = false;
    parents.forEach((parent) => {
        if (parent===child.key || parent.key===child.key) result=true;
    });
    return result;
  }
  
   buildTable(activeDays, issue, factor) {
    const phiTable = [0,0,0,0];
    Object.keys(activeDays).forEach((date)=> {
        const hasIssue = this.hasRelationship(activeDays[date].myIssues, issue);
        const hasFactor = this.hasRelationship(activeDays[date].myFactors, factor);
        if (hasIssue && hasFactor) phiTable[0]=phiTable[0]+1;
        if (!hasIssue && hasFactor) phiTable[1]=phiTable[1]+1;
        if (hasIssue && !hasFactor) phiTable[2]=phiTable[2]+1;
        if (!hasIssue && !hasFactor) phiTable[3]=phiTable[3]+1;
    });
    return phiTable;
  }
  
   tableAnalysis(phiTable) {
    const corr = ((phiTable[0]*phiTable[3])-(phiTable[1]*phiTable[2]))/ 
    Math.sqrt(
        (phiTable[0]+phiTable[1])*
        (phiTable[2]+phiTable[3])*
        (phiTable[0]+phiTable[2])*
        (phiTable[1]+phiTable[3])
        );
    return corr; // +1:-1 or NaN
  }
  
  isIssueInAnalysisTable(issue, analyisTable) {
    // active days >0
    // issue is set
    // factor is set
    return true;
  }

  canGenerateAnalyisTable(issue, factors, activeDays) {
    return true;
  }


  calculateReliability(phiTable) {
    const rel = (phiTable[0]+phiTable[1])/minNumOfEntries;
    return rel;
    //return minNumOfEntries;
  }


  // needs to receive the activeDays, issues, factors
  // returns a set of phiBoxes by issue/factors
  calculateCorrelations(issue, factors, activeDays) {       
    if (Object.keys(activeDays).length>0) {
      let factorScores = {};
  
      
      Object.keys(factors).forEach((key) => {
        const phiTable = this.buildTable(activeDays, issue, factors[key]);
        const corr = this.tableAnalysis(phiTable);
        const reliability = this.calculateReliability(phiTable);
        factorScores[key] = {corr, 
                            phi: phiTable,
                            reliability};
      });
    return factorScores;
  }
  return null;
  }

  generateCompleteAnalyisTable(issues, factors, activeDays) {
    let analyisTable = {};
    Object.keys(issues).forEach((key) => {
      analyisTable[key] = this.calculateCorrelations(issues[key], factors, activeDays);
    });
    return analyisTable;
  }
}

const analysisEngine = AnalysisEngine.getInstance(); 
export default analysisEngine 