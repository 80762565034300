import { createSlice } from '@reduxjs/toolkit';
import fixFactors from '../../tests/fixtures/fix-factors';

function highestKey(factors) {
  let highest = 1;
  for (const key in factors) {
    if (factors[key].key>highest) highest = factors[key].key
  }
  return highest;
}

export const factorSlice = createSlice({
  name: 'factors',
  initialState: {  
    factors: fixFactors,
    lastKey: 0,
  },

  reducers: {
    resetFactor:(state, action) => {
      state.factors = {    1: {key: 1,
        title: 'Factor placeholder',
        desc: 'please delete'
        }}
    },
    restoreFactorState(state,action) {
      state.factors = action.payload;
    },
    addUpdateFactor(state, action) {
      const newFactor  = action.payload;
      let lastKey = null
      // does it have a key? if so save over, or use the next highestkey
      if (newFactor.id===null) {
        lastKey = highestKey(state.factors);
        lastKey++;
        state.lastKey=lastKey;
        newFactor.key=lastKey;
      } else {
        lastKey = newFactor.id
        newFactor.key = lastKey
      }
      delete newFactor.id;
      state.factors[lastKey] = newFactor;
    },

    deleteFactor: (state, action) => {
      const id = action.payload
      delete state.factors[id];
    },

  },
});

export const { resetFactor, restoreFactorState, addUpdateFactor, deleteFactor } = factorSlice.actions;

export const selectFactors = state => state.factors.factors;


export default factorSlice.reducer;