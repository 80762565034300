import React from 'react';
import FormatDate from '../helpers/FormatDate';
import { useSelector, useDispatch } from 'react-redux';
import Day from './calendar/Day';
import {useHistory} from 'react-router-dom';
import {selectAnalysisTable, selectSelectedIssue, setComleteAnalysisTable, selectAnalysisFlag, setAnalysisFlag } from './analysis/AnalysisSlice';
import {selectToday, setSelectedDay} from './calendar/TemporalSlice';
import {addNewActiveDay, selectActiveDays, selectStartDate} from './calendar/CalendarSlice';

import {selectFactors} from './factor/factorSlice';
import {selectIssues} from './issue/IssueSlice';

import analysisEngine from './analysis/AnalysisEngine';
import years from '../helpers/years';
import newFormtDate from '../helpers/newDateFormat';

function ScrollCalendar (props) {
    const history = useHistory();
    const today = useSelector(selectToday);
    const factors = useSelector(selectFactors);
    const issues = useSelector(selectIssues);
    const activeDays = useSelector(selectActiveDays);

    const isSelectAnalysisFlag = useSelector(selectAnalysisFlag);
    const dispatch = useDispatch();

    if (isSelectAnalysisFlag) {
        const result = analysisEngine.generateCompleteAnalyisTable(issues, factors, activeDays);
        dispatch(setComleteAnalysisTable({result}));
        dispatch(setAnalysisFlag(false));
      }
    

    const listDays = [];
    const selectedIssue = useSelector(selectSelectedIssue);
    const analysisTable = useSelector(selectAnalysisTable);
    const currentAnalysis = (selectedIssue===null || analysisTable[selectedIssue.key]===undefined)? {}:analysisTable[selectedIssue.key];
    const startDate = useSelector(selectStartDate);

    let now = new Date();
        for (let d = now; d>=new Date(startDate.year,startDate.month,1); d.setDate(d.getDate() - 1)) {
            let currentDate = new Date(d);
            let [dayValue, niceDate] = newFormtDate(currentDate);
            let titleNo = currentDate.getDay();
            let dayObj =(activeDays[dayValue]!== undefined)? activeDays[dayValue] :{id: dayValue, title: titleNo, myIssues : [],myFactors: []};

            const calendarClick = () => {
                dispatch(addNewActiveDay({today: dayObj}));
                dispatch(setSelectedDay({today: dayValue}));
                let updatedPath = history.location.pathname+'#a'+dayValue;
                history.replace(updatedPath);
                history.push('/update_day');
             };

            listDays.push (
                (<Day
                key = {dayValue} 
                calendarClick= {onClick => calendarClick()}
                niceDate = {niceDate}
                date = {dayValue}
                aDay = {dayObj} 
                currentAnalysis = {currentAnalysis}
                selectedIssue = {selectedIssue}
                /> )
            );
                  //  titleNo = (titleNo===0)? 6:titleNo-1;
        }
                 /*
               let padding = listDays[0]['props']['aDay']['title'] || 0;
               let padDivs =[];
               for (let i=0; i<padding-1;i++) {
                   padDivs.push((<div key={i} className="blank_day day"></div>));
               }
               */
            
    
    return (<div>
        <section id="weekview"> {/* padDivs */} {listDays }</section>
        </div>
    )
}
           
export default ScrollCalendar;