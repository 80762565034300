import React, {useState} from 'react';
import { useDispatch } from 'react-redux';
import { deleteFactorFromIndex, deleteFactorFromCalendar } from '../calendar/CalendarSlice';
import { deleteFactor} from '../factor/factorSlice';

import Modal from '../../helpers/Modal';

function ManageFactor(props) {
    const dispatch = useDispatch();
    let factor = props.factor;
    const [show, setShow] = useState();

    function removeFactor(key) {
        dispatch(deleteFactorFromCalendar(key));
        dispatch(deleteFactorFromIndex(key));
        dispatch(deleteFactor(key));
    }
    return (
       <li  key={props.factor.key}>
           <div className="float-left">{props.factor.title}  </div>
            <div className="float-right"><span onClick={() => props.setValues({id:factor.key, title:factor.title, desc: factor.desc})}>&#9998;</span>&nbsp;|&nbsp;
            <span  onClick={()=> removeFactor(factor.key)}>&#10007;</span></div>
       {/* <Modal message="delete will remove the factor and all it's history in the calendar."></Modal> */}
       </li>
    );
// {payload: {day: theDay, factor: factor}}
}
export default ManageFactor;