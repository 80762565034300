import React, {useEffect} from 'react';
import { useSelector} from 'react-redux';

import {selectFactors} from '../factor/factorSlice';
import {selectIssues} from '../issue/IssueSlice';
import {selectApp} from '../../app/AppSlice';
import {selectCalendarState} from '../calendar/CalendarSlice';
import {selectToday} from '../calendar/TemporalSlice';

function SaveState () {

  const factors = useSelector(selectFactors);
  const issues = useSelector(selectIssues);
  const calendar = useSelector(selectCalendarState)
  const app = useSelector(selectApp);

  let output = {}
  output.factors = factors;
  output.issues = issues;
  output.calendar = calendar;
  output.app = app;

  let formatedData = JSON.stringify(output);

  useEffect(() => {    // Update the document title using the browser API    
    let data = new Blob([formatedData]);
    let url = window.URL.createObjectURL(data);
    document.getElementById('save_state').href = url;
  });

  const today = useSelector(selectToday);
  const downloadName = "CDC-backup-"+today.full +".cdc"
  return (<div>
    <a id="save_state" download={downloadName}>Download (save!)</a><br />
    </div>

  )
}

export default SaveState;