const 
fixIssues= {
        1: {key: 1,
        title: 'Neck Pain'
        },
        2: {key: 2,
        title: 'Bad Sleep'
    },
        3: {key: 3,
        title: 'Stomach pain'
    },         
        4: {key: 4,
        title: 'Headache',
        desc: 'pulsing eye busting pain'
        },         
        5: {key: 5,
        title: 'Embarrassing X',
        desc: 'not telling'
        },         
        6: {key: 6,
        title: 'Embarrassing Y',
        desc: 'NOT telling'
        },         
        7: {key: 7,
        title: 'Lucky!',
        desc: 'great'
        },         
        8: {key: 8,
        title: 'Won Lotto',
        desc: '1000!'
        }
    };


export default fixIssues;